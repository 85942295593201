<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const brands = computed(() => store.state.brands);

</script>

<template lang="html">
  <div class="sidebar-brands q-pa-sm">
    <div class="sidebar-item sidebar-title">
      {{ store.state.labels.sidebar_brands }}
    </div>
    <div
      v-for="(brand, index) in brands"
      :key="index"
      class="sidebar-item"
    >
      <router-link :to="`/catalog/brand/${brand.link}`">
        <img alt="" :src="brand.img" class="brand-logo q-py-sm" />
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

.sidebar-brands {
  .sidebar-item { text-align: center; }
  .sidebar-title { color: $blue-grey-8; font-size: 1.1rem; }
  .brand-logo { width: 100%; max-width: 170px; height: auto; }
}
</style>
